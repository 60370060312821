.scrollbar-container {
    scrollbar-width: thin;
    scrollbar-color: #cccccc83 #ffffff00;
}
ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

@keyframes accordion-expand {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}


.accordion-open {
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.accordion-closed {
  overflow: hidden;
  height: 0;
  transition: height 0.5s ease-in-out;
}