@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
}

span {
    font-family: "DM Sans", sans-serif !important;
    font-optical-sizing: auto;
}

.border-gradient {
    border: linear-gradient(
        90deg,
        rgba(241, 241, 241, 0.05),
        rgba(32, 101, 46, 0.05)
    );
    border-image-slice: 1;
}
